// 合同状态枚举
export const CONTRACT_STATUS = {
  FAIL: 0,
  AUDIT_PENDING: 1,
  EXEC_PENDING: 2,
  EXECUTING: 3,
  FINISH: 4,
};

export const CONTRACT_STATUS_OPTIONS = [
  { value: CONTRACT_STATUS.FAIL, label: '审核失败' },
  { value: CONTRACT_STATUS.AUDIT_PENDING, label: '待审核' },
  { value: CONTRACT_STATUS.EXEC_PENDING, label: '待履行' },
  { value: CONTRACT_STATUS.EXECUTING, label: '审核成功' },
  { value: CONTRACT_STATUS.FINISH, label: '已完成' },
];

// 合同类型枚举
export const CONTRACT_TYPES = {
  MAIN: 0,
  SUB: 1,
  SUPPLEMENT: 2,
};

export const CONTRACT_TYPE_OPTIONS = [
  { value: CONTRACT_TYPES.MAIN, label: '主合同' },
  { value: CONTRACT_TYPES.SUB, label: '子合同' },
  { value: CONTRACT_TYPES.SUPPLEMENT, label: '补充合同' },
];

// 合同收付类型枚举
export const CONTRACT_PAYMENT_TYPES = {
  PAYMENT: 0,
  RECEIPT: 1,
};

// 通知的优先级对应的TAG类型
export const NOTIFICATION_TYPES = {
  low: 'primary',
  middle: 'warning',
  high: 'danger',
};

// 通用请求头
export enum ContentTypeEnum {
  Json = 'application/json;charset=UTF-8',
  FormURLEncoded = 'application/x-www-form-urlencoded;charset=UTF-8',
  FormData = 'multipart/form-data;charset=UTF-8',
}

export enum OperationTypeTitle {
  Create = '添加一级菜单',
  CreateChildren = '添加子节点',
  Edit = '编辑节点',
}

export enum EditRoleTypeTitle {
  Create = '创建角色',
  Edit = '编辑角色',
}

export type SensitiveWordSettingType = 'Create' | 'Edit';

export enum SensitiveWordSettingTypeTitle {
  Create = '添加敏感词',
  Edit = '编辑敏感词',
}

export const HOME_PAGE_CONFIG_TYPES = {
  draft: '未发布',
  publish: '已发布',
  history: '历史版本',
};

export const HOME_PAGE_CONFIG_TYPES_COLORS = {
  draft: 'danger',
  publish: 'success',
  history: 'warning',
};

export const SOCIAL_CONFIG_TYPES = {
  forbidden: '禁用词',
  replace: '替换词',
};

export const SOCIAL_CONFIG_TYPES_COLORS = {
  forbidden: 'danger',
  replace: 'success',
};

export const ORDER_STATUS_TEXT_ENUM = {
  pending_payment: '待付款',
  pending_shipment: '待发货',
  shipped: '已发货',
  canceled: '已取消',
  completed: '已完成',
};

export const ORDER_STATUS_TAG_ENUM = {
  pending_payment: 'primary',
  pending_shipment: 'warning',
  shipped: 'warning',
  canceled: 'danger',
  completed: 'success',
};

// 订单来源；product_center=来萃好物，works=作品下单；user_share=用户分享;live=直播带货
export const ORDER_FROM_ENUM = {
  product_center: '来萃好物',
  works: '作品下单',
  user_share: '好物分享',
  live: '直播带货',
};

// 下单入口
export const ORDER_ENTRY_ENUM = {
  'android-miniprogram': '安卓小程序',
  'ios-miniprogram': '苹果小程序',
  'android-app': '安卓客户端',
  'ios-app': '苹果客户端',
  'devtools-miniprogram': '微信开发者工具',
  'srm-pc': 'SRM供应商管理系统',
  'windows-miniprogram': '微软桌面端',
  'mac-miniprogram': '苹果桌面端',
};

// 订单类型：product=普通商品；service=服务商品
export const ORDER_TYPE_ENUM = {
  product: '普通商品',
  service: '服务商品',
};

// 销售类型：platform=平台商品，personal=私域商品
export const ORDER_SALES_TYPE_ENUM = {
  platform: '平台商品',
  personal: '私域商品',
};

// 配送方式 merchant=商家配送，warehouse=仓库配送
export const ORDER_DELIVERY_TYPE_ENUM = {
  merchant: '商家配送',
  warehouse: '仓库配送',
};

// "售后状态 退款状态；wait=已申请待审核；reject=退款被拒绝；finish=退款成功"
export const ORDER_AFTER_SALES_STATUS_ENUM = {
  wait: '审核中',
  reject: '退款被拒绝',
  finish: '退款成功',
};

// 支付方式，wechat_pay=微信支付;alipay=支付宝支付;
export const ORDER_PAYMENT_TYPE_ENUM = {
  wechat_pay: '微信支付',
  alipay: '支付宝支付',
};

//  '积分，余额抵扣方式；use_balance=使用积分，use_points=使用余额;',
export const ORDER_DISCOUNT_TYPE_ENUM = {
  use_balance: '余额',
  use_points: '积分',
};

// 对账状态 对账状态(UNRECONCILED 未对账,RECONCILING 对账中,RECONCILED 已对账)
export const RECONCILIATION_STATUS_TYPE_ENUM = {
  UNRECONCILED: '未对账',
  RECONCILING: '对账中',
  RECONCILED: '已对账',
};

export const RECONCILIATION_STATUS_TAG_ENUM = {
  RECONCILING: 'warning',
  UNRECONCILED: 'danger',
  RECONCILED: 'success',
};

export const LIMITED_STATUS_TYPE_ENUM = {
  1: '限购',
  0: '不限购',
};

export const LIMITED_STATUS_TAG_ENUM = {
  1: 'danger',
  0: 'success',
};

// 适用范围；platform=平台;suppliers=供应商;app_user=用户
export const AFTER_SALES_REASON_SCOPE_ENUM = {
  platform: '平台',
  suppliers: '供应商',
  appUser: '用户',
};

export const AFTER_SALES_REASON_TYPE_ENUM = {
  refundAndReturns: '退货原因',
  refund: '退款原因',
  warranty: '保修原因',
};

export const RECONCILIATION_STATUS = {
  wait: '对账中',
  pass: '已对账',
};

export const RECONCILIATION_STATUS_TAG = {
  wait: 'warning',
  pass: 'success',
};
